let ReferralStatus;
let ReferralRoute;
let HealthService;
define([
"OApp",
"OEncryption",
"referrals/OhlConstants"
], function(OApp, OEncryption, OhlConstants) { "use strict";
	HealthService = OhlConstants.HealthService;
	ReferralStatus = OhlConstants.ReferralStatus;
	ReferralRoute = OhlConstants.ReferralRoute;
	const Urgency = OhlConstants.Urgency;
	const Region = OhlConstants.Region;
	const WaitTimes = OhlConstants.WaitTimes;
	const Language = OhlConstants.Language;
	const OwnerAssignment = OhlConstants.OwnerAssignment;
	const StandardApptTypes = OhlConstants.StandardApptTypes;
	let shownPKLoadError = false;
	
	let Referrals = function() {
		let listeners = [];
		
		const CACHE_KEY = "oceanReferralKey";
		
		function getCachedKey(key) {
			return sessionStorage && sessionStorage.getItem(CACHE_KEY + key);
		}
		function setCachedKey(key, value) {
			try { sessionStorage.setItem(CACHE_KEY + key, value); } catch (e) {
				console.log("unable to cache referral key", e);//eg Safari private mode
			}
		}
		
		function init() {
		}
		function getServiceDesc(offerings) {
			let msg = "";
			if(offerings)
				for (let i = 0; i < offerings.length; i++) {
					if (i > 0) msg += ", ";
					msg += HealthService[offerings[i].service];
				}
			return msg;
		}
		function getRtDesc(rt) {
			let msg = rt.title;
			msg += "<br>"+StrUtil.escapeHtml(AddressUtil.addressToString(rt.contactInfo));
			msg += "<br><br><b>Services:</b><br>";
			for (let i = 0; i < rt.offerings.length; i++) {
				msg += Referrals.HealthService[rt.offerings[i].service] +"<br>";
			}
			return msg;
		}
		function isFastDecryption(referral) {
			return getCachedKey(referral.referralRef) || referral.oneTimeKey ? true : false;
		}
		function decryptReferralWithOneTimeKey(referral, oneTimeKey) {
			setCachedKey(referral.referralRef, oneTimeKey);
			referral.oneTimeKey = oneTimeKey;
			return OSymmetricEncryption.decryptPtData(referral.encryptedPtData, oneTimeKey);
		}
		function decryptReferralPt(referral, site, callback, failureCallback, skipPrompt) {
			let pt;
			let existingOTK = referral.oneTimeKey || OSymmetricEncryption.getOneTimeEncryptionKeyFromAnchor();
			if (!existingOTK && referral.oneTimeKeyEncryptedWithSitePublicKey) {
				// pull from the browser cache, but only if this referral's OTK is still available via the private key at this site:
				existingOTK = getCachedKey(referral.referralRef);
			}
			if (existingOTK) {
				pt = null;
				try {
					pt = decryptReferralWithOneTimeKey(referral, existingOTK);
				} catch (e) {
					console.error(e);
				}
				if (pt) {
					callback(pt);					
					return;
				}
			}
			OSymmetricEncryption.isSecretKeyEntered(function(keysEntered) {
				if (skipPrompt && !keysEntered) {
					failureCallback();
					return;
				}
				OApp.getSiteNum(function(curSiteNum) {
					const encryptedOTK = referral.siteAccessKeys[curSiteNum];
					// first try to use symmetric encryption:
					if (encryptedOTK) {
						decryptReferralPtWithSEK(referral, encryptedOTK, callback, failureCallback);
					} else if (site && referral.oneTimeKeyEncryptedWithSitePublicKey) {
						// no symmetric key is available; we have to use the private key instead
						loadPrivateKeys(site, function() {
							pt = decryptReferralPtWithPrivateKey(referral, site, referral.oneTimeKeyEncryptedWithSitePublicKey, failureCallback);
							if (pt) {
								callback(pt);
							}
						}, true);
					} else {
						failureCallback(OUtils.t("can't decrypt - no site access", "Referrals.js.cant_decrypt_no_site_access"));					
					}
				});
			});
		}
		function decryptReferralPtWithPrivateKey(referral, site, oneTimeKeyEncryptedWithSitePublicKey, failureCallback) {
			try {
				const oneTimeKey = OAsymmetricEncryption.decryptWithPrivateKeys(getCachedPrivateKeys(site), oneTimeKeyEncryptedWithSitePublicKey);
				return decryptReferralWithOneTimeKey(referral, oneTimeKey);
			} catch (e) {
				if (failureCallback) {
					failureCallback(e);
				} else {
					throw e;
				}
			}
		}
		function decryptReferralPtWithSEK(referral, encryptedKey, callback, failureCallback) {
			OSymmetricEncryption.decryptPtWithSecretKeys
				(encryptedKey, referral.encryptedPtData, function(pt, oneTimeKey) {
					referral.oneTimeKey = oneTimeKey;
					setCachedKey(referral.id, oneTimeKey);
					callback(pt, oneTimeKey);
				}, failureCallback);
		}
		
		// Pass true to "isRiiDto" when referral being passed is a ReferralDwrDto. False when it is
		// of a ReferralInitialInfoDto. These two DTOs hold the appropriate key in different fields
		function getReferralOTK(referral, site, isRiiDto) {
			return $.Deferred(function(d) {
				let oneTimeKey = null;
				const encryptedOTK = isRiiDto ? null : referral.siteAccessKeys[site.siteNum];
				if (encryptedOTK) {
					OSymmetricEncryption.decryptWithSecretKeys({
						encryptedOneTimeKey: encryptedOTK
					}, function(decryptedText, otk) {
						d.resolve(otk);
					});
				} else {
					// ReferralInitialInfo holds the otk encrypted with _sites_ pk in a different field compared to referral
					var otkEncryptedWithSitePublicKey = isRiiDto ? referral.oneTimeKeyEncryptedWithSrcPublicKey : referral.oneTimeKeyEncryptedWithSitePublicKey;
					oneTimeKey = referral.oneTimeKey ||
						getCachedKey(referral.referralRef) ||
						OAsymmetricEncryption.decryptWithPrivateKeys(getCachedPrivateKeys(site), otkEncryptedWithSitePublicKey);
					d.resolve(oneTimeKey);
				}
			});
		}
		
		const privateKeyCache = {};
		function getCachedPrivateKeys(site) {
			return privateKeyCache[site.privateKeysEncryptedWithSecretKey.iv];
		}
		function loadPrivateKeys(site, callback, verifyPrivateKeyEncryptedWithCurrentSEK, skipCache) {
			function failedPKLoad(e) {
				if (!shownPKLoadError) {
					const msg = OUtils.t("The private encryption key for this site isn't accessible using the shared encryption key entered in this browser. " +
						"The private key is needed to decrypt and view incoming eRequests.", "Referrals.js.private_key_needed_message");
					OUtils.multiAnswerAsk(msg, [
						{
							text: OUtils.t("OK", "Referrals.js.ok_button"),
							click: function() { callback(); },
						}, {
							text: OUtils.t("Show Error Details", "Referrals.js.show_error_details_button"),
							click: function() {
								OSymmetricEncryption.getSecretKeys(function(keys) {
									OUtils.showNote(e, {title: OUtils.t("Encryption Key Error", "Referrals.js.encryption_key_error_label")});
									callback();
								});
							}
						}, {
							text: OUtils.t("Enter New Shared Encryption Key to Access Private Key", "Referrals.js.enter_new_encrypted_key_button"),
							click: function() {
								OSymmetricEncryption.getNewSecretKey(function() {
									loadPrivateKeys(site, callback, verifyPrivateKeyEncryptedWithCurrentSEK, skipCache);
								});
							}
						}
					], OUtils.t("Private Key Inaccessible", "Referrals.js.private_key_inaccessible_label"), {});
					shownPKLoadError = true;
				}
				else {
					callback();
				}
			}
			if (!site.privateKeysEncryptedWithSecretKey) {
				return;
			}
			if (!skipCache) {
				const cached = getCachedPrivateKeys(site);
				if (cached) {
					callback(cached);
					return;
				}
			}
			OSymmetricEncryption.getPrimarySecretKey(function() {
				OSymmetricEncryption.decryptWithSecretKeys({
					block: site.privateKeysEncryptedWithSecretKey,
					failureCallback: failedPKLoad
				}, function decrypted(privateKeysStr, oneTimeKey, sharedKeyUsed) {
					if (privateKeysStr) {
						const privateKeys = privateKeysStr.split("|");
						privateKeyCache[site.privateKeysEncryptedWithSecretKey.iv] = privateKeys;
						if (verifyPrivateKeyEncryptedWithCurrentSEK) {
							requirejs(["referrals/RtKeySettings"], function(RtKeySettings) {
								OApp.initTranslationFlagAndI18n({ namespaces: ["configuration"] }, function() {
									RtKeySettings.checkToReEncryptPrivateKey(site, privateKeys, sharedKeyUsed, null, null);
								});
							});				
						}
						callback(privateKeys);
					} else {
						callback();
					}
				});
			}, failedPKLoad);
		}
		function resetShownPKLoadErrorFlag() {
			shownPKLoadError = false;
		}
		function getSearchStrForService(svcDesc) {
			switch (svcDesc) { //NOSONAR
				case HealthService.ADOLESCENT_MEDICINE: return OUtils.t("teenager adolescent clinic", "Referrals.js.teenager_adolescent_clinic");
				case HealthService.BACK_PAIN: return OUtils.t("Back clinic", "Referrals.js.back_clinic");
				case HealthService.BIRTH_CONTROL: return OUtils.t("Birth Control contraception", "Referrals.js.birth_control_contraception");
				case HealthService.COSMETICS: return OUtils.t("Cosmetic Clinic", "Referrals.js.cosmetic_clinic");
				case HealthService.COUNSELING: return OUtils.t("Mental Health Counseling", "Referrals.js.mental_health_counseling");
				case HealthService.CT: return OUtils.t("ct scan", "Referrals.js.ct_scan");
				case HealthService.DENTAL_HYGIENE: return OUtils.t("Dental Hygienist", "Referrals.js.dental_hygienist");
				case HealthService.DENTISTRY: return OUtils.t("Dentist", "Referrals.js.dentist");
				case HealthService.DEVELOPMENTAL_AND_DISABILITY: return OUtils.t("Developmental and Disability Services", "Referrals.js.developmental_disability_services");
				case HealthService.DIABETES_EDUCATION: return OUtils.t("Diabetes", "Referrals.js.diabetes");
				case HealthService.DIAGNOSTIC_IMAGING: return OUtils.t("Diagnostic Imaging", "Referrals.js.diagnostic_imaging");
				case HealthService.DIAGNOSTIC_TESTS: return "";
				case HealthService.DIETITIAN: return OUtils.t("Registered Dietitian Nutritionist", "Referrals.js.registered_dietitian_nutritionist");
				case HealthService.DRIVING_SERVICES: return OUtils.t("Driver safety testing", "Referrals.js.driver_safety_testing");
				case HealthService.ECG: return OUtils.t("electrocardiogram", "Referrals.js.electrocardiogram");
				case HealthService.EEG: return OUtils.t("electroencephalogram", "Referrals.js.electroencephalogram");
				case HealthService.EMG: return OUtils.t("electromyogram", "Referrals.js.electromyogram");
				case HealthService.ECHOCARDIOGRAM: return OUtils.t("Echocardiogram", "Referrals.js.echocardiogram");
				case HealthService.EMERGENCY_SHELTER: return OUtils.t("Emergency Women's Shelter", "Referrals.js.emergency_womens_shelter");
				case HealthService.FAMILY_SERVICES: return OUtils.t("Family counseling Services", "Referrals.js.family_counseling_services");
				case HealthService.FERTILITY: return OUtils.t("infertility clinic", "Referrals.js.infertility_clinic");
				case HealthService.FLUOROSCOPY: return OUtils.t("x-ray Fluoroscopy", "Referrals.js.xray_fluoroscopy");
				case HealthService.GENETICS: return OUtils.t("Genetic Counseling", "Referrals.js.genetic_counseling");
				case HealthService.GERIATRICS: return OUtils.t("Geriatrician", "Referrals.js.geriatrician");
				case HealthService.GOVERNMENT_SERVICE_OFFICE: return OUtils.t("driver's license office", "Referrals.js.drivers_license_office");
				case HealthService.GYNECOLOGY: return OUtils.t("Gynecologist", "Referrals.js.gynecologist");
				case HealthService.HAIR_REMOVAL: return OUtils.t("Hair Removal Clinic", "Referrals.js.hair_removal_clinic");
				case HealthService.HEMATOLOGY: return OUtils.t("Hematologist", "Referrals.js.hematologist");
				case HealthService.HEPATOLOGY: return OUtils.t("Hepatologist", "Referrals.js.hepatologist");
				case HealthService.HOLTER_MONITORING: return OUtils.t("Ambulatory ECG Monitoring (Holter)", "Referrals.js.ambulatory_ecg_monitoring");
				case HealthService.HOUSING_AND_HOMELESSNESS_SERVICES: return OUtils.t("Housing and Homelessness Services", "Referrals.js.housing_homelessness_services");
				case HealthService.INFECTIOUS_DISEASE: return OUtils.t("Infectious Disease", "Referrals.js.infectious_disease");
				case HealthService.INTERNAL_MEDICINE: return OUtils.t("Internal Medicine", "Referrals.js.internal_medicine");
				case HealthService.KINESIOLOGY: return OUtils.t("Kinesiologist", "Referrals.js.kinesiologist");
				case HealthService.LABORATORY: return OUtils.t("medical laboratories", "Referrals.js.medical_laboratories");
				case HealthService.LASER_SERVICES: return OUtils.t("Laser Services Hair Cosmetics", "Referrals.js.laser_services_hair_cosmetics");
				case HealthService.LLS_CHIROPODY_PODIATRY: return OUtils.t("Chiropodist Podiatrist", "Referrals.js.chiropodist_podiatrist");
				case HealthService.LOOP_MONITORING: return OUtils.t("Cardiac Loop Monitoring", "Referrals.js.cardiac_loop_monitoring");
				case HealthService.MAMMOGRAPHY: return OUtils.t("Mammography", "Referrals.js.mammography");
				case HealthService.MASSAGE_THERAPY: return OUtils.t("Massage Therapy", "Referrals.js.massage_therapy");
				case HealthService.MEAL_SERVICES: return OUtils.t("Elderly Senior Meal Services", "Referrals.js.elderly_senior_meal_services");
				case HealthService.MEDICAL_MARIJUANA: return OUtils.t("Medical Marijuana Clinic", "Referrals.js.medical_marijuana_clinic");
				case HealthService.MEDICAL_MICROBIOLOGY: return OUtils.t("Medical Microbiologist", "Referrals.js.medical_microbiologist");
				case HealthService.MENTAL_HEALTH: return OUtils.t("Mental Health Counseling", "Referrals.js.mental_health_counseling_duplicate");
				case HealthService.MISCELLANEOUS: return "";
				case HealthService.MRI: return OUtils.t("Magnetic resonance imaging", "Referrals.js.magnetic_resonance_imaging");
				case HealthService.NATUROPATHY: return OUtils.t("Naturopathic medicine", "Referrals.js.naturopathic_medicine");
				case HealthService.PAIN_MANAGEMENT: return OUtils.t("Chronic Pain Management", "Referrals.js.chronic_pain_management");
				case HealthService.PALLIATIVE_CARE: return OUtils.t("Palliative Care Specialist", "Referrals.js.palliative_care_specialist");
				case HealthService.PALLIATIVE_HOMECARE: return OUtils.t("Palliative Homecare", "Referrals.js.palliative_homecare");
				case HealthService.PEDIATRIC_CARDIOLOGY: return "";
				case HealthService.PEDIATRIC_DENTISTRY: return "";
				case HealthService.PEDIATRIC_PSYCHIATRY: return OUtils.t("Pediatric Psychiatry", "Referrals.js.pediatric_psychiatry");
				case HealthService.PEDIATRIC_SURGERY: return "";
				case HealthService.PEDIATRICS: return OUtils.t("Pediatrics", "Referrals.js.pediatrics");
				case HealthService.PHARMACY: return OUtils.t("pharma", "Referrals.js.pharma");
				case HealthService.RESPIRATORY_THERAPY: return OUtils.t("Respiratory Therapy Clinic", "Referrals.js.respiratory_therapy_clinic");
				case HealthService.SEXUAL_HEALTH: return OUtils.t("Sexual Health Counseling", "Referrals.js.sexual_health_counseling");
				case HealthService.SLEEP_MEDICINE: return OUtils.t("Sleep Apnea Clinic", "Referrals.js.sleep_apnea_clinic");
				case HealthService.SMOKING_CESSATION: return OUtils.t("Smoking Cessation Counseling", "Referrals.js.smoking_cessation_counseling");
				case HealthService.SOCIAL_WORK: return OUtils.t("Social Work RSW", "Referrals.js.social_work_rsw");
				case HealthService.SPEECH_THERAPY: return OUtils.t("Speech Language Pathology", "Referrals.js.speech_language_pathology");
				case HealthService.SPORT_THERAPY: return OUtils.t("Sports Medicine", "Referrals.js.sports_medicine");
				case HealthService.SURGERY: return OUtils.t("Surgeon", "Referrals.js.surgeon");
				case HealthService.TRAVEL_MEDICINE: return OUtils.t("Travel Medicine Clinic", "Referrals.js.travel_medicine_clinic");
				case HealthService.ULTRASOUND: return OUtils.t("Radiology Ultrasound", "Referrals.js.radiology_ultrasound");
				case HealthService.VASCULAR_STUDIES: return OUtils.t("Venous Doppler Studies", "Referrals.js.venous_doppler_studies");
				case HealthService.X_RAY: return OUtils.t("Radiology X-Ray", "Referrals.js.radiology_xray");
			}
			return svcDesc;
		}
		
		function addOrUpdateReferral(referral) {//called by server
			for (let i = 0; i < listeners.length; i++) {
				listeners[i].addOrUpdate(referral);
			}
		}
		function removeReferral(referral) {//called by server
			for (let i = 0; i < listeners.length; i++) {
				listeners[i].remove(referral);
			}
		}
		function addListener(l) {
			listeners.push(l);
		}
		function formatDistance(dist) {
			let distStr;
			if (dist === undefined || dist === null) {
				return "";
			}
			if (dist < 3) {
				distStr = dist.toFixed(2);
			} else {
				distStr = dist.toFixed(0);
			}
			return OUtils.t("{{distance}} km", "Referrals.js.km_label", {distance: distStr});
		}
		function getEmailDto(pt, saveDto) {
			return {
				ptPreferredName: pt.getPreferredOrFirstName(),
				ptEmail: pt.demographics.address.email,
				ptPreferredLanguage: pt.demographics.language,
				eFaxSenderRequestedPtNotification: !!(saveDto && saveDto.emailDto && saveDto.emailDto.eFaxSenderRequestedPtNotification),
			};
		}
		function getERequestTerm(rt) {
			if (rt && rt.eRequestTitle === 'EORDERS') {
				return OUtils.t('order', "Referrals.js.order_term");
			} else if (rt && rt.eRequestTitle === 'ESUBMISSIONS') {
				return OUtils.t('submission', "Referrals.js.submission_term");
			} else {
				return OUtils.t('referral', "Referrals.js.referral_term");
			}
		}
		function getERequestTermWithCaps(rt) {
			if (rt && rt.eRequestTitle === 'EORDERS') {
				return OUtils.t('eOrder', "Referrals.js.eorder_term");
			} else if (rt && rt.eRequestTitle === 'ESUBMISSIONS') {
				return OUtils.t('eSubmission', "Referrals.js.esubmission_term");
			} else {
				return OUtils.t('eReferral', "Referrals.js.ereferral_term");
			}
		}
		function getTerm(r, rt, caps) {
			if (r && r.eConsult) {
				return OUtils.t("eConsult", "Referrals.js.econsult_term");
			}
			if (r && r.selfReferral) {
				return caps ? OUtils.t("eRequest", "Referrals.js.erequest_term") : OUtils.t("request", "Referrals.js.request_term");
			}
			if (r && r.route === ReferralRoute.PRINTED_REFERRAL && rt && rt.rtType === "REPOSITORY") {
				return caps ? OUtils.t("Requisition", "Referrals.js.requisition_label") : OUtils.t("requisition", "Referrals.js.requisition_label_duplicate");
			}
			return caps ? getERequestTermWithCaps(rt) : getERequestTerm(rt);
		}
		function determineIfCreateEmrPt(referral, site, allowCreatePatientFromReferral) {
			const supportedEMRs = ["TELUS_PSS", "PSS_API", "MED_ACCESS", "ACCURO", "OSCAR", "OSCAR_PRO"];
			if (supportedEMRs.indexOf(site.emrType) === -1) {
				return false;
			}
			switch (referral.communicationType) {
				case "E_REFERRAL":
					return allowCreatePatientFromReferral;
				case "FORM_SUBMISSION":
					return site.createPatientFromWebsiteForm;
				case "ONLINE_BOOKING":
					return site.createPatientFromOnlineBooking;
				default:
					return false;
			}
		}
		
		function findLanguages(searchStr) {
			return Object.entries(Language)
				.filter(entry => entry[1].toLowerCase().startsWith(searchStr.toLowerCase()))
				.map(entry => {
					return {internalValue: entry[0], value: entry[1], label: entry[1]};
				});
		}
	
		return {	//Public Methods
			init: init,
			getTerm: getTerm,
			getServiceDesc: getServiceDesc,
			getRtDesc: getRtDesc,
			resetShownPKLoadErrorFlag: resetShownPKLoadErrorFlag,
			
			decryptReferralWithOneTimeKey: decryptReferralWithOneTimeKey,
			decryptReferralPt: decryptReferralPt,
			loadPrivateKeys: loadPrivateKeys,
			isFastDecryption: isFastDecryption,

			getSearchStrForService: getSearchStrForService,
			formatDistance: formatDistance,
			
			HealthService: HealthService,
			Region: Region,
			Urgency: Urgency,
			WaitTimes: WaitTimes,
			Language: Language,
			OwnerAssignment: OwnerAssignment,
			StandardApptTypes: StandardApptTypes,
			SMALL_SAMPLE: 10,
			getProfIdName: OUtils.t("Professional ID", "Referrals.js.professional_id"),
			
			addOrUpdateReferral: addOrUpdateReferral,
			removeReferral: removeReferral,
			getEmailDto: getEmailDto,
			addListener: addListener,
			getReferralOTK: getReferralOTK,
			determineIfCreateEmrPt: determineIfCreateEmrPt,
			findLanguages: findLanguages,
		};
	}();
	return Referrals;
});
